import {
  add,
  Arcified,
  Contour,
  ContourProperty,
  mul,
  normalize,
  PartMfg,
  sub,
  Unit,
  Vector,
} from "@oshcut/oshlib"
import { PartDfm } from "types"
import { drawBbox, drawContour, drawPolyline } from "./drawContour"
import Log from "./logs"
import unitScale from "./unitScale"

// Render each pc onto the context
let renderCache: Record<string, HTMLCanvasElement> = {}

export function clearRenderCache() {
  renderCache = {}
}

export type RenderPcProps = {
  /** The canvas on which to render the part. */
  ctx: CanvasRenderingContext2D

  /** The part to render. */
  arcified: Arcified
  /** Applies the given translation to the part. */
  position?: Vector
  /** Applies the given rotation to the part. */
  rotation?: number
  /** The scale factor when drawing the part, in pixels per inch. */
  scale: number

  /**
   * Applies an additional rotation to the part, centered around rotatingPartsRotationCenter. This is useful for
   * rendering a group of parts that are being rotated. 
   */
  rotatingPartsDeltaRotation?: number
  /** Whether to render selection frame and handles around the part. */
  rotatingPartsRotationCenter?: Vector

  /** The ids of the selected contours, if any. */
  selectedContourIds?: Contour['id'][]
  /** The color to use when rendering selected contours. Default is #6622ff. */
  selectedContourColor?: string

  /** The part's DFM. If provided, will render bend allowance regions on selected contours. */
  dfm?: PartDfm | PartMfg
  /** Array of points to highlight on the part. Each point will have a small red circle rendered around it. */
  alertPoints?: Vector[]

  /** The kerf width, in inches. Default is 0.006. */
  kerf?: number
  /** Whether to render the raw entities. */
  showRawEntities?: boolean

  /** Whether the part is selected. Affects the fill color. */
  selected?: boolean
  /** Whether the part has an error. Affects the fill color. */
  error?: boolean
  /** The fill color. Overrides the default. */
  fillColor?: string
  /** 
   * The colors to use when rendering each contour. Overrides contourFillColor. Useful for coloring each subpart in a
   * pre-nest a different color.
   */
  contourFillColors?: { contourId: Contour['id'], color: string }[]
  /** The stroke color of the part. Default is transparent. */
  strokeColor?: string

  /** 
   * Whether to cache rendered parts offscreen. It is currently a little broken, and won't invalidate the cache if the
   * part geometry of properties change.
   */
  cache?: boolean
  /** Whether to render to a local, offscreen canvas, before rendering to the main canvas. */
  local?: boolean
  /** Whether to render debug information. */
  debug?: boolean
  /** If provided, renders each of the given regions in a different color. */
  colorCodeBendPlanes?: boolean
}

/**
 * Render a part at a given position and rotation onto a canvas context
 */
export function renderPc({
  ctx,
  arcified,
  dfm,
  position = { x: 0, y: 0 },
  rotation = 0,
  alertPoints,
  scale,
  selected = false,
  error = false,
  selectedContourIds = [],
  selectedContourColor = '#6622ff',
  colorCodeBendPlanes = true,
  kerf = 0.006,
  debug,
  showRawEntities,
  fillColor,
  contourFillColors,
  strokeColor,
  cache = true,
  local = true,

}: RenderPcProps) {

  if (!fillColor) {
    if (selected) {
      if (error) {
        fillColor = '#ff6b4277' // orange
      } else {
        fillColor = '#7B8794'
      }
    } else {
      if (error) {
        fillColor = '#ff000077' // red
      } else {
        fillColor = '#90b8da'
      }
    }
  }

  // Key should contain enough information so that we cache as many parts as might be rendered together simultaneously,
  // if rendering many parts at once. But it should not contain so much information that we cache too many parts.
  let key = `${arcified.id}-${arcified.version}-${fillColor}-${strokeColor}`

  // This will be used to translate the part to put it in the center of the local canvas
  let partTrans = {
    x: -arcified.bbox.min.x + 0.5,
    y: -arcified.bbox.min.y + 0.5
  }

  let _width = (arcified.bbox.max.x - arcified.bbox.min.x + 1) * scale
  let _height = (arcified.bbox.max.y - arcified.bbox.min.y + 1) * scale

  let useLocalCanvas = _width * _height < 1e6 // If the part is not too big, render it to an offscreen (local) canvas

  useLocalCanvas &&= local

  if (!renderCache[key] || !useLocalCanvas || !cache) {
    // Render this part onto a local canvas. We can then draw the local canvas as many times as we want on the main canvas very quickly.


    let localCanvas

    let targetCtx: CanvasRenderingContext2D // The part will be rendered to this context
    if (useLocalCanvas && cache) {

      // If rendering onto a local canvas, we'll do the transformations later on

      localCanvas = document.createElement('canvas')
      localCanvas.width = _width
      localCanvas.height = _height
      let localCtx = localCanvas.getContext('2d')
      if (!localCtx) throw new Error('Could not get context of local canvas')
      localCtx.scale(scale, scale) // Render in native pixel/pixel resolution
      localCtx.translate(partTrans.x, partTrans.y) // Position the part in the center of the local canvas
      targetCtx = localCtx
    } else {

      targetCtx = ctx

      // If rendering directly onto the canvas, do all the transformations up front

      // Push second transformation matrix: parts will be rotated and translated

      // These last two still may not be right....
      // TODO: They are not perfect. It would be nice to place center of rotation at geometric center of part. We should be able to do that with two additional translations. But, maybe best to leave it now that we've been doing manual transformations in other places (like hitTest and getTransformedBoundingBox)
      ctx.save()
      ctx.translate(position.x, position.y)
      ctx.rotate(rotation)
    }

    // We're now in partworld

    // TODO: Better styles

    /** 
     * Styles
     * 
     * 
     * Fill Style
     * Normal: 7a94f077 (light blue)
     * Selected part: e7cbb077 (light orange)
     * Colliding part: ff000077 (light red)
     * Colliding and selected part: #ff8a6577 (orange red)
     * 
     * Stroke Color
     * Normal: 999999 (light gray) -- this is the part outline without any cut path superimposed
     * Cut path: #000000 (black)
     * Selected contour without a cut path: #9ab9ff (light blue)
     * Selected contour with a cut path: #2266ff (blue)
     * 
     * Stroke thickness
     * Normal: 1 pixel or actual kerf width
     * Selected contour: 3 pixel or actual kerf width
     * 
     */

    if (arcified) {
      targetCtx.lineCap = 'round'
      targetCtx.lineJoin = 'round'

      targetCtx.lineWidth = Math.max(kerf, 1 / scale)

      let partStrokeStyle = '#00000000' // transparent
      let partFillStyle

      targetCtx.strokeStyle = partStrokeStyle
      targetCtx.fillStyle = fillColor

      // TODO: How to make re-render when contours are loaded?

      // First, group contours according to their fill color.

      let closedContourGroupsByColor: Record<string, Contour[]> = {}

      for (let c of arcified.contours.filter(c => c.isClosed && c.type !== 'nonTopological' && c.type !== 'intersecting')) {
        let specificFillColor = contourFillColors?.find(cfc => cfc.contourId === c.id)?.color
        fillColor = specificFillColor ?? fillColor ?? partFillStyle
        if (!closedContourGroupsByColor.hasOwnProperty(fillColor)) {
          closedContourGroupsByColor[fillColor] = []
        }
        closedContourGroupsByColor[fillColor].push(c)
      }

      // First, render all closed contours (grouping by color) and fill with the evenodd rule.
      for (let color in closedContourGroupsByColor) {
        targetCtx.beginPath()
        closedContourGroupsByColor[color]
          .forEach(c => {
            drawContour(targetCtx, c)
            // drawPolyline(ctx, c.poly)
          })
        targetCtx.fillStyle = color
        targetCtx.fill('evenodd') // The 'evenodd' rule just saved my bacon!
        targetCtx.stroke()
      }

      // // Draw offset contour for fun
      // for (let dist = 0; dist > -40; dist -= 3) {
      //   let offsetContours = clipOffsetContours(part.arcified.contours.filter(c => c.isClosed), dist)

      //   targetCtx.strokeStyle = '#ff0000'
      //   targetCtx.lineWidth = 1 / scale
      //   targetCtx.beginPath()
      //   offsetContours.forEach(c => {
      //     drawContour(targetCtx, c)
      //   })
      //   targetCtx.stroke()
      // }
      // for (let dist = 0; dist < 40; dist += 3) {
      //   let offsetContours = clipOffsetContours(part.arcified.contours.filter(c => c.isClosed), dist)

      //   targetCtx.strokeStyle = '#0000ff'
      //   targetCtx.lineWidth = 1 / scale
      //   targetCtx.beginPath()
      //   offsetContours.forEach(c => {
      //     drawContour(targetCtx, c)
      //   })
      //   targetCtx.stroke()
      // }
      // targetCtx.strokeStyle = '#00000000'

      const bendInfo = arcified?.bendInfoV5 ?? arcified.bendInfo
      if (colorCodeBendPlanes && bendInfo) {
        targetCtx.save()
        ctx.strokeStyle = '#ff0000'
        ctx.fillStyle = '#ff000066'
        ctx.lineWidth = Math.max(4 / scale, kerf)
        const colors = [
          '#1b9e77', // 0 Teal
          '#d95f02', // 1 Orange
          '#7570b3', // 2 Blue-purple
          '#e7298a', // 3 Magenta
          '#66a61e', // 4 Green
          '#e6ab02', // 5 Yellow
          '#a6761d', // 6 Yellow-Brown
          '#666666', // 7 Grey
          '#8dd3c7', // 8 Cyan
          '#ffffb3',
          '#bebada',
          '#fb8072',
          '#80b1d3',
          '#fdb462',
          '#b3de69',
          '#fccde5',
        ]
        for (let region of bendInfo.regions) {
          targetCtx.fillStyle = colors[region.id % colors.length] + 'aa'
          targetCtx.strokeStyle = colors[region.id % colors.length]
          let center
          if ('polyGroup' in region && region.polyGroup?.length) {
            targetCtx.beginPath()
            if (region.polyTrimmed) {
              for (let poly of region.polyTrimmed) {
                drawPolyline(
                  targetCtx,
                  poly,
                  true)
              }
            } else {
              for (let poly of region.polyGroup) {
                drawPolyline(
                  targetCtx,
                  poly.outerPoly,
                  true)
                for (let inner of poly.innerPolys) {
                  drawPolyline(
                    targetCtx,
                    inner,
                    true)
                }
              }
            }
            center = mul(region.polyGroup[0].outerPoly.reduce((acc, pt) => add(acc, pt), { x: 0, y: 0 }), 1 / region.polyGroup[0].outerPoly.length)
            targetCtx.stroke()
            targetCtx.fill('evenodd')
          }
          if ('contourGroup' in region && region.contourGroup?.length) {
            targetCtx.beginPath()
            if (region.contourGroupTrimmed) {
              for (let contour of region.contourGroupTrimmed) {
                drawContour(targetCtx, contour.outerContour)
                for (let inner of contour.innerContours) {
                  drawContour(targetCtx, inner)
                }
              }
            } else {
              for (let contour of region.contourGroup) {
                drawContour(targetCtx, contour.outerContour)
                for (let inner of contour.innerContours) {
                  drawContour(targetCtx, inner)
                }
              }
            }
            center = mul(add(region.contourGroup[0].outerContour.bbox.min, region.contourGroup[0].outerContour.bbox.max), 0.5)
            targetCtx.stroke()
            targetCtx.fill('evenodd')
          }

          if (center) {
            targetCtx.save()
            targetCtx.scale(1, -1)
            targetCtx.font = `${Math.max(0.03, 28 / scale)}px sans-serif`
            targetCtx.fillStyle = '#000000'
            targetCtx.fillText(region.id.toString(), center.x, -center.y)
            targetCtx.restore()
          }
        }

        for (let bend of bendInfo.bends) {
          for (let line of bend.lineGroup) {

            if (line["baRegion"]) {
              // Dark gray (if this is showing, the baRegion was not stitched together correctly)
              targetCtx.fillStyle = '#333'
              targetCtx.beginPath()
              if ('entities' in line["baRegion"]) {
                drawContour(targetCtx, line["baRegion"])
              } else {
                drawPolyline(targetCtx, line["baRegion"], true)
              }
              targetCtx.fill()
            }

            if (line["baRegion-left"]) {
              // Red
              targetCtx.fillStyle = '#ff0000'
              targetCtx.beginPath()
              if ('entities' in line["baRegion-left"]) {
                drawContour(targetCtx, line["baRegion-left"])
              } else {
                drawPolyline(targetCtx, line["baRegion-left"], true)
              }
              targetCtx.fill()
            }

            if (line["baRegion-right"]) {
              // Green
              targetCtx.fillStyle = '#00ff00'
              targetCtx.beginPath()
              if ('entities' in line["baRegion-right"]) {
                drawContour(targetCtx, line["baRegion-right"])
              } else {
                drawPolyline(targetCtx, line["baRegion-right"], true)
              }
              targetCtx.fill()
            }


            if (line.stripes) {
              let index = 0
              for (let stripe of line.stripes) {
                for (let stripePiece of stripe) {
                  if ('contour' in stripePiece) {
                    // Alternate purple/yellow
                    targetCtx.fillStyle = ['#dd47', '#70e7'][index % 2]
                    targetCtx.beginPath()
                    drawContour(targetCtx, stripePiece.contour)
                    targetCtx.fill()
                  } else {
                    // Alternate purple/yellow
                    targetCtx.fillStyle = ['#dd47', '#70e7'][index % 2]
                    targetCtx.beginPath()
                    drawPolyline(targetCtx, stripePiece, true)
                    targetCtx.fill()
                  }
                }
                index++
              }
            }
          }
        }

        targetCtx.restore()
      }

      if (bendInfo) {

        // When to show a line's allowance regions?
        // 1. When the line is selected
        // 2. When the line has errors or warnings

        // baRegion-baRegion intersection: warning (collidingWarning)
        // mfRegion-mfRegion intersection: ok
        // baRegion-mfRegion intersection: error (colliding)
        // mfRegion off of part: error (unsupported)

        // Changing this now to use an explicit list of stuff to show, instead of each line having those supported or colliding properties.

        // Consumers typically use two different types of dfm objects, one that includes costing and one that does not,
        // so reshape the data to be consistent.
        let bendLineDfm
        if (dfm?.bending) {
          if ('dfm' in dfm?.bending) {
            bendLineDfm = dfm?.bending?.dfm.bendLineDfm
          } else {
            bendLineDfm = dfm?.bending?.bendLineDfm
          }
        }

        targetCtx.lineWidth = 2 / scale
        for (let bend of bendInfo.bends) {

          let contourIds = bend.originalContourIds
          const isSelected = contourIds.some(cId => selectedContourIds.includes(cId))
          for (let lineIndex = 0; lineIndex < bend.lineGroup.length; lineIndex++) {
            let line = bend.lineGroup[lineIndex]
            let lineDfm = bendLineDfm?.find(le => le.bendId === bend.id && le.lineIndex === lineIndex)

            if (isSelected && lineDfm) {

              targetCtx.save()
              // mfRegion: error on unsupported and colliding
              if (lineDfm.colliding || lineDfm.unsupportedSections.length > 0) {
                targetCtx.fillStyle = '#ff004444'
                targetCtx.strokeStyle = '#ff004499'
              } else {
                targetCtx.fillStyle = '#00000022'
                targetCtx.strokeStyle = '#00000055'
              }
              if (lineDfm.mfRegion) {
                targetCtx.beginPath()
                drawPolyline(
                  targetCtx,
                  lineDfm.mfRegion,
                  true)

                // targetCtx.fill('evenodd')
                targetCtx.lineWidth = 4 / scale
                targetCtx.setLineDash([4 / scale, 4 / scale])
                targetCtx.lineCap = 'butt'
                // targetCtx.beginPath()
                // targetCtx.moveTo(lineDfm.mfRegion[1].x, lineDfm.mfRegion[1].y)
                // targetCtx.lineTo(lineDfm.mfRegion[2].x, lineDfm.mfRegion[2].y)
                // targetCtx.moveTo(lineDfm.mfRegion[3].x, lineDfm.mfRegion[3].y)
                // targetCtx.lineTo(lineDfm.mfRegion[0].x, lineDfm.mfRegion[0].y)
                targetCtx.stroke()
              }
              targetCtx.restore()

              if (lineDfm.unsupportedSections.length > 0) {
                targetCtx.save()
                targetCtx.fillStyle = '#ff000044'
                for (let i = 0; i < lineDfm.unsupportedSections.length; i += 2) {
                  if (lineDfm.unsupportedSections[i + 1] - lineDfm.unsupportedSections[i] > 1e-3) {
                    targetCtx.beginPath()
                    let tangent = normalize(sub(line.end, line.start))
                    let normal = { x: -tangent.y, y: tangent.x }
                    let pt0 = add(line.start, mul(tangent, lineDfm.unsupportedSections[i]), mul(normal, lineDfm.minFlange))
                    let pt1 = add(line.start, mul(tangent, lineDfm.unsupportedSections[i + 1]), mul(normal, lineDfm.minFlange))
                    let pt2 = add(line.start, mul(tangent, lineDfm.unsupportedSections[i + 1]), mul(normal, -lineDfm.minFlange))
                    let pt3 = add(line.start, mul(tangent, lineDfm.unsupportedSections[i]), mul(normal, -lineDfm.minFlange))
                    targetCtx.moveTo(pt0.x, pt0.y)
                    targetCtx.lineTo(pt1.x, pt1.y)
                    targetCtx.lineTo(pt2.x, pt2.y)
                    targetCtx.lineTo(pt3.x, pt3.y)
                    targetCtx.lineTo(pt0.x, pt0.y)
                    targetCtx.fill()
                  }
                }
                targetCtx.restore()
              }
              if (lineDfm.unsupportedSectionsWarning.length > 0) {
                targetCtx.save()
                targetCtx.fillStyle = '#ff990044'
                for (let i = 0; i < lineDfm.unsupportedSectionsWarning.length; i += 2) {
                  if (lineDfm.unsupportedSectionsWarning[i + 1] - lineDfm.unsupportedSectionsWarning[i] > 1e-3) {
                    targetCtx.beginPath()
                    let tangent = normalize(sub(line.end, line.start))
                    let normal = { x: -tangent.y, y: tangent.x }
                    let pt0 = add(line.start, mul(tangent, lineDfm.unsupportedSectionsWarning[i]), mul(normal, lineDfm.minFlange))
                    let pt1 = add(line.start, mul(tangent, lineDfm.unsupportedSectionsWarning[i + 1]), mul(normal, lineDfm.minFlange))
                    let pt2 = add(line.start, mul(tangent, lineDfm.unsupportedSectionsWarning[i + 1]), mul(normal, -lineDfm.minFlange))
                    let pt3 = add(line.start, mul(tangent, lineDfm.unsupportedSectionsWarning[i]), mul(normal, -lineDfm.minFlange))
                    targetCtx.moveTo(pt0.x, pt0.y)
                    targetCtx.lineTo(pt1.x, pt1.y)
                    targetCtx.lineTo(pt2.x, pt2.y)
                    targetCtx.lineTo(pt3.x, pt3.y)
                    targetCtx.lineTo(pt0.x, pt0.y)
                    targetCtx.fill()
                  }
                }
                targetCtx.restore()
              }

              targetCtx.save()
              // baRegion: error on colliding, warning on collidingWarning
              if (lineDfm.colliding) {
                targetCtx.fillStyle = '#ff004444'
                targetCtx.strokeStyle = '#ff004499'
              } else if (lineDfm.collidingWarning) {
                targetCtx.fillStyle = '#ff990044'
                targetCtx.strokeStyle = '#ff990099'
              } else {
                targetCtx.fillStyle = '#00000022'
                targetCtx.strokeStyle = '#00000055'
              }
              if (lineDfm.baRegion) {
                targetCtx.beginPath()
                drawPolyline(
                  targetCtx,
                  lineDfm.baRegion,
                  true)
                targetCtx.fill('evenodd')
                targetCtx.stroke()
              }
              targetCtx.restore()

            }
          }
        }
      }


      // Second, render all open contours, but do not fill.
      arcified.contours
        .filter(c => !c.isClosed || c.type === 'nonTopological' || c.type === 'intersecting')
        .forEach(c => {
          targetCtx.beginPath()
          drawContour(targetCtx, c, false)
          // drawPolyline(ctx, c.poly)
          targetCtx.stroke()
        })


      // Render raw entities
      if (showRawEntities && arcified.rawEntities) {
        targetCtx.strokeStyle = '#ff0000'
        targetCtx.lineWidth = Math.max(kerf * 1.5, 0.9 / scale)
        targetCtx.beginPath()
        drawContour(targetCtx, { entities: arcified.rawEntities }, false)
        targetCtx.stroke()
        targetCtx.lineWidth = Math.max(kerf, 1 / scale)
      }

      // Render cut paths (and contour cutting order)

      arcified.contours
        .forEach(function renderContourCutPath(contour) {   // Using a named function to make it easier to find and to profile :-)

          let cutPathStrokeStyle
          let cutPathLineWidth

          // Render the contour as selected if it is selected, or if the contour it was generated from is selected.

          const isSelected = selectedContourIds.some(cId => (cId === contour.id || cId === contour.autoGeneratedOriginContourId))

          // Choose correct contour properties
          const cuttingMethod = contour.cuttingMethod

          // If a contour has no cut path, just render the contour itself
          let contoursToRender: Pick<Contour, 'entities'>[] = [contour]

          if (isSelected) {
            cutPathLineWidth = Math.max(kerf, 4 / scale) // 4 pixels or kerf width
            cutPathStrokeStyle = selectedContourColor
          } else {
            cutPathLineWidth = Math.max(kerf, 1 / scale) // 1 pixel or kerf width
            if (cuttingMethod === 'CUTTING_METHOD_IGNORE') {
              cutPathStrokeStyle = '#00000055'
            } else if (cuttingMethod === 'CUTTING_METHOD_CUT'
              || cuttingMethod === 'CUTTING_METHOD_ENGRAVE'
              || cuttingMethod === 'CUTTING_METHOD_BEND'
              || cuttingMethod === 'CUTTING_METHOD_STITCH'
              || cuttingMethod === 'CUTTING_METHOD_HOLE') {
              cutPathStrokeStyle = '#000000'
            } else {
              cutPathStrokeStyle = '#ff0000'
            }
          }

          let cutPathLineDash: number[]
          if (cuttingMethod === 'CUTTING_METHOD_ENGRAVE') {
            cutPathLineDash = [Math.max(0.01, 5 / scale), Math.max(0.01, 5 / scale)]
          } else if (cuttingMethod === 'CUTTING_METHOD_BEND'
            || cuttingMethod === 'CUTTING_METHOD_STITCH'
            || cuttingMethod === 'CUTTING_METHOD_HOLE') {
            cutPathLineDash = [Math.max(0.014, 7 / scale), Math.max(0.008, 4 / scale), Math.max(0.004, 2 / scale), Math.max(0.008, 4 / scale)]
          } else {
            cutPathLineDash = []
          }
          targetCtx.setLineDash(cutPathLineDash)
          targetCtx.strokeStyle = cutPathStrokeStyle

          if (strokeColor) {
            targetCtx.strokeStyle = strokeColor
          }

          targetCtx.lineWidth = cutPathLineWidth
          let debugColors = [
            '#ff000077',
            '#00ff0077',
            '#0000ff77'
          ]
          let debugColorIdx = 0
          for (let c of contoursToRender) {
            targetCtx.beginPath()
            drawContour(targetCtx, c, false)
            if (debug) targetCtx.strokeStyle = debugColors[debugColorIdx]
            targetCtx.stroke()
            debugColorIdx = (debugColorIdx + 1) % debugColors.length

            // Render removed entities
            for (let e of c.entities) {
              if (e.removed) {
                let fakeContour = { entities: [e] }
                targetCtx.beginPath()
                targetCtx.strokeStyle = '#ff0000'
                drawContour(targetCtx, fakeContour, false)
                targetCtx.stroke()
              }
            }
          }

          targetCtx.setLineDash([])

          if (cuttingMethod === 'CUTTING_METHOD_BEND') {
            // Render bend indicator and angle
            // Choose side of bend farthest from center of part
            // No, actually, choose side of bend closest to top-left, so the directions of the arrows will be the same for same-signed bends
            // let d2Start = norm2(sub(contour.entities[0].start, partCtr))
            // let d2End = norm2(sub(contour.entities[0].end, partCtr))
            let lineEntity = contour.entities[0]
            if (lineEntity.type !== 'LINE') throw new Error('Expected bend contour to be a line')
            let d2Start = -lineEntity.start.x + lineEntity.start.y
            let d2End = -lineEntity.end.x + lineEntity.end.y
            let pt1, pt2, tang, norm
            if (d2Start > d2End) {
              pt1 = lineEntity.start
              pt2 = lineEntity.end
            } else {
              pt1 = lineEntity.end
              pt2 = lineEntity.start
            }
            tang = normalize(sub(pt2, pt1))
            norm = { x: -tang.y, y: tang.x }


            targetCtx.strokeStyle = '#000000'
            targetCtx.lineWidth = 1 / scale
            if ((contour.bendAngle ?? 0) < 0) {
              let a0t = 8 / scale * Math.cos(Math.PI * 7 / 4)
              let a0n = 12 / scale * Math.sin(Math.PI * 7 / 4)
              let a1t = a0t - 5 / scale
              let a1n = a0n - 0 / scale
              let a2t = a0t + 0 / scale
              let a2n = a0n - 5 / scale
              let p0 = add(add(mul(tang, a0t), mul(norm, a0n)), pt1)
              let p1 = add(add(mul(tang, a1t), mul(norm, a1n)), pt1)
              let p2 = add(add(mul(tang, a2t), mul(norm, a2n)), pt1)

              let a3t = 8 / scale * Math.cos(Math.PI / 4)
              let a3n = 12 / scale * Math.sin(Math.PI / 4)
              let a4t = a3t - 5 / scale
              let a4n = a3n + 0 / scale
              let a5t = a3t + 0 / scale
              let a5n = a3n + 5 / scale
              let p3 = add(add(mul(tang, a3t), mul(norm, a3n)), pt1)
              let p4 = add(add(mul(tang, a4t), mul(norm, a4n)), pt1)
              let p5 = add(add(mul(tang, a5t), mul(norm, a5n)), pt1)

              targetCtx.beginPath()
              targetCtx.ellipse(pt1.x, pt1.y, 8 / scale, 12 / scale, Math.atan2(tang.y, tang.x), Math.PI / 4, Math.PI * 7 / 4, false)
              targetCtx.lineTo(p1.x, p1.y)
              targetCtx.moveTo(p0.x, p0.y)
              targetCtx.lineTo(p2.x, p2.y)

              targetCtx.moveTo(p3.x, p3.y)
              targetCtx.lineTo(p4.x, p4.y)
              targetCtx.moveTo(p3.x, p3.y)
              targetCtx.lineTo(p5.x, p5.y)
              targetCtx.stroke()

            } else if ((contour.bendAngle ?? 0) > 0) {

              let a0t = 8 / scale * Math.cos(Math.PI * 5 / 4)
              let a0n = 12 / scale * Math.sin(Math.PI * 5 / 4)
              let a1t = a0t + 5 / scale
              let a1n = a0n - 0 / scale
              let a2t = a0t - 0 / scale
              let a2n = a0n - 5 / scale
              let p0 = add(add(mul(tang, a0t), mul(norm, a0n)), pt1)
              let p1 = add(add(mul(tang, a1t), mul(norm, a1n)), pt1)
              let p2 = add(add(mul(tang, a2t), mul(norm, a2n)), pt1)

              let a3t = 8 / scale * Math.cos(Math.PI * 3 / 4)
              let a3n = 12 / scale * Math.sin(Math.PI * 3 / 4)
              let a4t = a3t + 5 / scale
              let a4n = a3n + 0 / scale
              let a5t = a3t - 0 / scale
              let a5n = a3n + 5 / scale
              let p3 = add(add(mul(tang, a3t), mul(norm, a3n)), pt1)
              let p4 = add(add(mul(tang, a4t), mul(norm, a4n)), pt1)
              let p5 = add(add(mul(tang, a5t), mul(norm, a5n)), pt1)

              targetCtx.beginPath()
              targetCtx.ellipse(pt1.x, pt1.y, 8 / scale, 12 / scale, Math.atan2(tang.y, tang.x), Math.PI * 3 / 4, Math.PI * 5 / 4, true)
              targetCtx.lineTo(p1.x, p1.y)
              targetCtx.moveTo(p0.x, p0.y)
              targetCtx.lineTo(p2.x, p2.y)

              targetCtx.moveTo(p3.x, p3.y)
              targetCtx.lineTo(p4.x, p4.y)
              targetCtx.moveTo(p3.x, p3.y)
              targetCtx.lineTo(p5.x, p5.y)
              targetCtx.stroke()
            }

            targetCtx.save()
            targetCtx.scale(1, -1)
            const fontSize = Math.max(0.03, 16 / scale)
            targetCtx.font = `${fontSize}px sans-serif`
            targetCtx.fillStyle = '#000000'

            let text = toConventional(contour.bendAngle)?.angle + '°'
            let textMetrics = targetCtx.measureText(text)
            let textCtr = add(pt1, mul(tang, -30 / scale))
            targetCtx.fillText(text, textCtr.x - textMetrics.width / 2, -textCtr.y + fontSize * 0.35)
            targetCtx.restore()
          }

          if (cuttingMethod === 'CUTTING_METHOD_HOLE' && contour.holeType === 'HOLE_TYPE_REAM' && contour.properties.circle) {
            // Render ream indicator
            // Choose point at the two o'clock position outside the circle
            let maxRadius = contour.properties.circle.r
            let generatedContour = arcified.contours.find(c => contour.id === c.autoGeneratedOriginContourId)
            if (generatedContour) {
              let circleEntity = generatedContour.entities[0]
              if (circleEntity.type !== 'CIRCLE') throw new Error('Expected hole contour to be a circle')
              maxRadius = Math.max(maxRadius, circleEntity.r)
            } else {
              console.log('No generated contour found for ream')
              console.log(contour.autoGeneratedOriginContourId)
            }
            const fontSize = Math.max(0.03, 16 / scale)
            let point0 = {
              x: contour.properties.circle.x + maxRadius * Math.cos(Math.PI / 6),
              y: contour.properties.circle.y + maxRadius * Math.sin(Math.PI / 6)
            }
            let point1 = {
              x: point0.x + fontSize * Math.cos(Math.PI / 6),
              y: point0.y + fontSize * Math.sin(Math.PI / 6)
            }
            let point2 = {
              x: point1.x + fontSize * 0.5,
              y: point1.y
            }

            targetCtx.beginPath()
            targetCtx.strokeStyle = '#000000'
            targetCtx.lineWidth = 1 / scale
            targetCtx.moveTo(point0.x, point0.y)
            targetCtx.lineTo(point1.x, point1.y)
            targetCtx.lineTo(point2.x, point2.y)
            targetCtx.stroke()


            targetCtx.save()
            targetCtx.scale(1, -1)
            targetCtx.font = `${fontSize}px sans-serif`
            targetCtx.fillStyle = '#000000'


            let partUnits = arcified.units ?? 'in'
            let reamUnits: Unit = partUnits[partUnits.length - 1] === 'm' ? 'mm' : 'in'
            let reamDigits = reamUnits === 'mm' ? 2 : 4
            let reamScale = unitScale[reamUnits]
            let text = reamUnits === 'in' ?
              `⌀ ${((contour.holeFinishedDiameter ?? 0) * reamScale).toFixed(reamDigits)}" REAM`
              :
              `⌀ ${((contour.holeFinishedDiameter ?? 0) * reamScale).toFixed(reamDigits)} mm REAM`

            let textCtr = point2
            targetCtx.fillText(text, textCtr.x, -textCtr.y + fontSize * 0.35)
            targetCtx.restore()

          }


          // Render tap indicator
          if (cuttingMethod === 'CUTTING_METHOD_HOLE' && contour.holeType === 'HOLE_TYPE_TAP' && contour.properties.circle) {
            // Choose point at the two o'clock position outside the circle
            let maxRadius = contour.properties.circle.r
            let generatedContour = arcified.contours.find(c => contour.id === c.autoGeneratedOriginContourId)
            if (generatedContour) {
              let circleEntity = generatedContour.entities[0]
              if (circleEntity.type !== 'CIRCLE') throw new Error('Expected hole contour to be a circle')
              maxRadius = Math.max(maxRadius, circleEntity.r)
            } else {
              console.log('No generated contour found for tap')
              console.log(contour.autoGeneratedOriginContourId)
            }
            const fontSize = Math.max(0.03, 16 / scale)
            let point0 = {
              x: contour.properties.circle.x + maxRadius * Math.cos(Math.PI / 6),
              y: contour.properties.circle.y + maxRadius * Math.sin(Math.PI / 6)
            }
            let point1 = {
              x: point0.x + fontSize * Math.cos(Math.PI / 6),
              y: point0.y + fontSize * Math.sin(Math.PI / 6)
            }
            let point2 = {
              x: point1.x + fontSize * 0.5,
              y: point1.y
            }

            targetCtx.save()
            targetCtx.translate(contour.properties.circle.x, contour.properties.circle.y)
            targetCtx.rotate(-rotation)
            targetCtx.translate(-contour.properties.circle.x, -contour.properties.circle.y)

            targetCtx.beginPath()
            targetCtx.strokeStyle = '#000000'
            targetCtx.lineWidth = 1 / scale
            targetCtx.moveTo(point0.x, point0.y)
            targetCtx.lineTo(point1.x, point1.y)
            targetCtx.lineTo(point2.x, point2.y)
            targetCtx.stroke()


            targetCtx.save()
            targetCtx.scale(1, -1)
            targetCtx.font = `${fontSize}px sans-serif`
            targetCtx.fillStyle = '#000000'

            let text = `${contour.holeThread?.replaceAll('-', '–') ?? '???'}`
            let textCtr = point2
            targetCtx.fillText(text, textCtr.x, -textCtr.y + fontSize * 0.35)
            targetCtx.restore()

            targetCtx.restore()
          }

          // Render hardware indicator
          if (cuttingMethod === 'CUTTING_METHOD_HOLE' && contour.holeType === 'HOLE_TYPE_HARDWARE' && contour.properties.circle) {
            // Choose point at the two o'clock position outside the circle
            let maxRadius = contour.properties.circle.r
            let generatedContour = arcified.contours.find(c => contour.id === c.autoGeneratedOriginContourId)
            if (generatedContour) {
              let circleEntity = generatedContour.entities[0]
              if (circleEntity.type !== 'CIRCLE') throw new Error('Expected hole contour to be a circle')
              maxRadius = Math.max(maxRadius, circleEntity.r)
            } else {
              console.log('No generated contour found for hardware')
              console.log(contour.autoGeneratedOriginContourId)
            }
            const fontSize = Math.max(0.03, 16 / scale)
            let point0 = {
              x: contour.properties.circle.x + maxRadius * Math.cos(Math.PI / 6),
              y: contour.properties.circle.y + maxRadius * Math.sin(Math.PI / 6)
            }
            let point1 = {
              x: point0.x + fontSize * Math.cos(Math.PI / 6),
              y: point0.y + fontSize * Math.sin(Math.PI / 6)
            }
            let point2 = {
              x: point1.x + fontSize * 0.5,
              y: point1.y
            }

            targetCtx.beginPath()
            targetCtx.strokeStyle = '#000000'
            targetCtx.lineWidth = 1 / scale
            targetCtx.moveTo(point0.x, point0.y)
            targetCtx.lineTo(point1.x, point1.y)
            targetCtx.lineTo(point2.x, point2.y)
            targetCtx.stroke()

            targetCtx.save()
            targetCtx.scale(1, -1)

            targetCtx.font = `${fontSize}px sans-serif`
            targetCtx.fillStyle = '#000000'
            let text = `Hardware`
            let textCtr = point2
            targetCtx.fillText(text, textCtr.x, -textCtr.y + fontSize * 0.35)
            targetCtx.restore()
          }


        })



      if (alertPoints) {
        for (let pt of alertPoints) {
          targetCtx.beginPath()
          let rad = 10 / scale
          targetCtx.lineWidth = 2 / scale
          targetCtx.strokeStyle = '#ff0000'
          targetCtx.ellipse(pt.x, pt.y, rad, rad, 0, 0, 2 * Math.PI)
          targetCtx.stroke()
        }
      }

      // Third, render rough polylines for debugging.
      if (debug) {
        targetCtx.lineWidth = Math.max(0.014, 2 / scale)
        arcified.contours
          .forEach(c => {
            if (c.type === 'outer')
              targetCtx.strokeStyle = '#ff00ff'
            else if (c.type === 'inner')
              targetCtx.strokeStyle = '#00ffff'
            else
              targetCtx.strokeStyle = '#ff7700'
            targetCtx.beginPath()
            drawPolyline(targetCtx, c.poly)
            targetCtx.stroke()

            if (c.type === 'outer')
              targetCtx.strokeStyle = '#ff00ff'
            else if (c.type === 'inner')
              targetCtx.strokeStyle = '#00ffff'
            else
              targetCtx.strokeStyle = '#ff7700'
            targetCtx.beginPath()
            drawPolyline(targetCtx, c.finePoly)
            targetCtx.stroke()

            if (c.piercePolys) {
              for (let pp of c.piercePolys) {
                targetCtx.beginPath()
                drawPolyline(targetCtx, pp)
                targetCtx.stroke()
              }
            }
          })

        targetCtx.strokeStyle = '#ff0000'
        targetCtx.lineWidth = Math.max(0.014, 2 / scale)

      }

      // Draw bounding boxes for debugging.
      if (debug) {
        targetCtx.strokeStyle = '#00ffff'
        targetCtx.lineWidth = Math.max(0.014, 2 / scale)
        targetCtx.beginPath()
        drawBbox(targetCtx, arcified.bbox)
        targetCtx.stroke()
      }

      // Draw partcoord uuid
      if (debug) {
        let partCtr = mul(add(arcified.bbox.min, arcified.bbox.max), 0.5)
        targetCtx.save()
        targetCtx.scale(1, -1)
        targetCtx.fillStyle = '#000000'
        targetCtx.font = '1px sans-serif'

        targetCtx.fillText(arcified.id, partCtr.x, -partCtr.y)
        targetCtx.restore()
      }
    }

    if (!useLocalCanvas) {
      // Pop matrix
      ctx.restore()
      // We're back in inchland
    }

    if (cache && localCanvas) {
      renderCache[key] = localCanvas // localCanvas will only be defined if useLocalCanvas is true
    }

  }


  if (useLocalCanvas) {
    ctx.save()

    // Push second transformation matrix: parts will be rotated and translated
    ctx.translate(position.x, position.y)
    ctx.rotate(rotation)

    ctx.translate(-partTrans.x, -partTrans.y)

    if (Math.abs(((rotation % (Math.PI / 2)) + 0.5) % 1 - 0.5) < 1e-9)
      ctx.imageSmoothingEnabled = false
    // Draw the localCtx onto the ctx
    try {
      ctx.drawImage(renderCache[key], 0, 0, renderCache[key].width / scale, renderCache[key].height / scale)
    } catch (ex) {
      Log.error(ex)
    }

    ctx.restore()
  }
}


/**
 * Converts an angle from Oshcut's internal format to the conventional format.
 * @param angle Bend angle in Oshcut's internal format
 * @returns An object containing properties angle and direction in the conventional format.
 */
function toConventional(angle: number | undefined) {
  // 0 angle should result in direction of 1
  if (angle == null) return null
  return { angle: 180 - Math.abs(angle), direction: Math.sign(angle) || 1 }
}


const constants = {
  TAB_UPLOAD_PARTS: 'TAB_UPLOAD_PARTS',
  TAB_SELECT_MATERIAL: 'TAB_SELECT_MATERIAL',
  TAB_SHIPPING_LEAD_TIME: 'TAB_SHIPPING_LEAD_TIME',
  TAB_CHECKOUT: 'TAB_CHECKOUT',

  ACTION_OPEN_LOGIN_MODAL_DIALOG: 'ACTION_OPEN_LOGIN_MODAL_DIALOG',
  ACTION_CLOSE_LOGIN_MODAL_DIALOG: 'ACTION_CLOSE_LOGIN_MODAL_DIALOG',
  ACTION_ATTEMPTED_LOGIN: 'ACTION_ATTEMPTED_LOGIN',
  ACTION_USER_IS_LOGGED_IN: 'ACTION_USER_IS_LOGGED_IN',
  ACTION_LOGOUT_USER: 'ACTION_LOGOUT_USER',
  ACTION_SET_CUSTOMER: 'ACTION_SET_CUSTOMER',
  ACTION_ADD_MATERIAL_FILTER: 'ACTION_ADD_MATERIAL_FILTER',
  ACTION_REMOVE_MATERIAL_FILTER: 'ACTION_REMOVE_MATERIAL_FILTER',

  // PartView actions
  ACTION_ALL_MATERIALS_LOADED: 'ACTION_ALL_MATERIALS_LOADED',
  ACTION_ALL_POWDERS_LOADED: 'ACTION_ALL_POWDERS_LOADED',
  ACTION_ALL_TAPS_LOADED: 'ACTION_ALL_TAPS_LOADED',
  ACTION_MESSAGES_LOADED: 'ACTION_MESSAGES_LOADED',
  ACTION_CREATE_PART: 'ACTION_CREATE_PART',
  ACTION_CREATE_ORDER_PART: 'ACTION_CREATE_ORDER_PART',
  ACTION_REMOVE_PART: 'ACTION_REMOVE_PART',
  ACTION_REMOVE_PARTS: 'ACTION_REMOVE_PARTS',
  ACTION_REMOVE_ALL_PARTS: 'ACTION_REMOVE_ALL_PARTS',
  ACTION_REMOVE_ORDER_PART: 'ACTION_REMOVE_ORDER_PART',
  ACTION_REMOVE_ORDER_PARTS: 'ACTION_REMOVE_ORDER_PARTS',
  ACTION_UNREMOVE_PART: 'ACTION_UNREMOVE_PART',
  ACTION_ARCIFIED_LOADED: 'ACTION_ARCIFIED_LOADED',
  ACTION_DFM_LOADED: 'ACTION_DFM_LOADED',
  ACTION_ORDER_LOADED: 'ACTION_ORDER_LOADED',
  ACTION_LIBRARY_PART_LOADED: 'ACTION_LIBRARY_PART_LOADED',
  ACTION_LIBRARY_PARTS_UPDATED: 'ACTION_LIBRARY_PARTS_UPDATED',
  ACTION_ORDER_MERGE: 'ACTION_ORDER_MERGE',
  ACTION_ORDER_PARTS_LOADED: 'ACTION_ORDER_PARTS_LOADED',
  ACTION_SET_PART_STATUS: 'ACTION_SET_PART_STATUS',
  ACTION_VIEW_PART: 'ACTION_VIEW_PART',
  ACTION_SET_SELECTED_PARTS: 'ACTION_SET_SELECTED_PARTS',
  ACTION_SELECT_CONTOURS: 'ACTION_SELECT_CONTOURS',
  ACTION_DESELECT_CONTOURS: 'ACTION_DESELECT_CONTOURS',
  ACTION_TOGGLE_SELECT_CONTOURS: 'ACTION_TOGGLE_SELECT_CONTOURS',
  ACTION_SELECT_CONTOURS_WITH_FILTER: 'ACTION_SELECT_CONTOURS_WITH_FILTER',
  ACTION_SELECT_SIMILAR_CONTOURS: 'ACTION_SELECT_SIMILAR_CONTOURS',
  ACTION_SET_UI_SELECTED_CONTOUR_PROPERTIES: 'ACTION_SET_UI_SELECTED_CONTOUR_PROPERTIES',
  ACTION_UPDATE_CONTOUR_PROPERTIES: 'ACTION_UPDATE_CONTOUR_PROPERTIES',
  ACTION_SET_CUSTOM_SIZE: 'ACTION_SET_CUSTOM_SIZE',
  ACTION_SET_UNITS: 'ACTION_SET_UNITS',
  ACTION_SET_MATERIAL: 'ACTION_SET_MATERIAL',
  ACTION_SET_QUANTITY: 'ACTION_SET_QUANTITY',
  ACTION_SET_QUANTITY_VALID: 'ACTION_SET_QUANTITY_VALID',
  ACTION_SET_PART_PROPERTY: 'ACTION_SET_PART_PROPERTY',
  ACTION_SHOW_ERROR_PANEL: 'ACTION_SHOW_ERROR_PANEL',
  ACTION_HIDE_ERROR_PANEL: 'ACTION_HIDE_ERROR_PANEL',
  ACTION_SET_ALERT_POINTS: 'ACTION_SET_ALERT_POINTS',
  ACTION_TOGGLE_EXPAND_PART_INFO_ITEM: 'ACTION_TOGGLE_EXPAND_PART_INFO_ITEM',
  ACTION_BEGIN_HOVER_PART_INFO_ITEM: 'ACTION_BEGIN_HOVER_PART_INFO_ITEM',
  ACTION_END_HOVER_PART_INFO_ITEM: 'ACTION_END_HOVER_PART_INFO_ITEM',
  ACTION_INVALIDATE_QUOTE: 'ACTION_INVALIDATE_QUOTE',
  ACTION_SET_QUOTE: 'ACTION_SET_QUOTE',
  ACTION_SET_PART_NAME: 'ACTION_SET_PART_NAME',
  ACTION_SET_SELECTED_SHIPPING: 'ACTION_SET_SELECTED_SHIPPING',
  ACTION_SET_SELECTED_LEAD: 'ACTION_SET_SELECTED_LEAD',
  ACTION_SET_BILLING_EQUALS_SHIPPING: 'ACTION_SET_BILLING_EQUALS_SHIPPING',
  ACTION_CLEAR_CART: 'ACTION_CLEAR_CART',
  ACTION_SET_SUBMITTED_ORDER: 'ACTION_SET_SUBMITTED_ORDER',
  ACTION_SHOW_LINK_ANIMATOR: 'ACTION_SHOW_LINK_ANIMATOR',
  ACTION_HIDE_LINK_ANIMATOR: 'ACTION_HIDE_LINK_ANIMATOR',
  ACTION_TOGGLE_COLOR_CODE_BENDS: 'ACTION_TOGGLE_COLOR_CODE_BENDS',
  ACTION_TOGGLE_SHOW_BEND_ALLOWANCE: 'ACTION_TOGGLE_SHOW_BEND_ALLOWANCE',
  ACTION_TOGGLE_NO_BEND_TRANSFORMS: 'ACTION_TOGGLE_NO_BEND_TRANSFORMS',
  ACTION_TOGGLE_SHOW_STACKED: 'ACTION_TOGGLE_SHOW_STACKED',
  ACTION_TOGGLE_SHOW_CONVEX_DECOMPOSITION: 'ACTION_TOGGLE_SHOW_CONVEX_DECOMPOSITION',
  ACTION_SET_PERCENT_BEND_TRANSFORM: 'ACTION_SET_PERCENT_BEND_TRANSFORM',
  ACTION_SET_NEST_RESULT: 'ACTION_SET_NEST_RESULT', // Set the result of the nesting operation during checkout
  ACTION_SET_BEND_ADVANCED_EXPANDED: 'ACTION_SET_BEND_ADVANCED_EXPANDED',

  BTN_PRIMARY: 'BTN_PRIMARY',
  BTN_SECONDARY: 'BTN_SECONDARY',
  BTN_TERTIARY: 'BTN_TERTIARY',

  // Control modes
  CONTROL_MODE_PAN: 'CONTROL_MODE_PAN',
  CONTROL_MODE_SELECT: 'CONTROL_MODE_SELECT',

  // Contour cutting methods
  CUTTING_METHOD_CUT: 'CUTTING_METHOD_CUT',
  CUTTING_METHOD_ENGRAVE: 'CUTTING_METHOD_ENGRAVE',
  CUTTING_METHOD_IGNORE: 'CUTTING_METHOD_IGNORE',
  CUTTING_METHOD_BEND: 'CUTTING_METHOD_BEND',
  CUTTING_METHOD_STITCH: 'CUTTING_METHOD_STITCH',
  CUTTING_METHOD_HOLE: 'CUTTING_METHOD_HOLE',

  // Hole types
  HOLE_TYPE_STANDARD: 'HOLE_TYPE_STANDARD',
  HOLE_TYPE_DRILL: 'HOLE_TYPE_DRILL',
  HOLE_TYPE_REAM: 'HOLE_TYPE_REAM',
  HOLE_TYPE_TAP: 'HOLE_TYPE_TAP',
  HOLE_TYPE_HARDWARE: 'HOLE_TYPE_HARDWARE',

  // Part statuses
  PART_STATUS_UPLOADING: 'PART_STATUS_UPLOADING', // The part is being uploaded to the server
  PART_STATUS_UPLOAD_ERROR: 'PART_STATUS_UPLOAD_ERROR', // There was an error uploading the part, the part was not uploaded
  PART_STATUS_CONVERTING: 'PART_STATUS_CONVERTING', // The part is being converted to a dxf
  PART_STATUS_CONVERT_ERROR: 'PART_STATUS_CONVERT_ERROR', // The part could not be converted
  PART_STATUS_UNFOLD_ERROR: 'PART_STATUS_UNFOLD_ERROR', // The part could not be unfolded
  PART_STATUS_PROCESSING: 'PART_STATUS_PROCESSING', // The part has been uploaded and is being processed for the first time
  PART_STATUS_PROCESSING_ERROR: 'PART_STATUS_PROCESSING_ERROR', // The part was uploaded but could not be processed
  PART_STATUS_NOT_LOADED: 'PART_STATUS_NOT_LOADED', // The part has not been loaded
  PART_STATUS_LOADING: 'PART_STATUS_LOADING', // An existing part's arcified json is loading.
  PART_STATUS_LOADING_ERROR: 'PART_STATUS_LOADING_ERROR', // Occurs when a part's arcified json could not be loaded.
  PART_STATUS_READY: 'PART_STATUS_READY', // The part was processed successfully
  PART_STATUS_REPROCESSING: 'PART_STATUS_REPROCESSING', // The part is being reprocessed (but we have the old part in memory still)

  DFM_STATUS_INVALID: 'DFM_STATUS_INVALID', // The dfm check has not started or has been invalidated
  DFM_STATUS_PROCESSING: 'DFM_STATUS_PROCESSING', // The dfm check is in progress
  DFM_STATUS_PROCESSING_ERROR: 'DFM_STATUS_PROCESSING_ERROR', // The dfm check failed to complete
  DFM_STATUS_COMPLETE: 'DFM_STATUS_COMPLETE', // The dfm check completed successfully (this doesn't mean that the check found no errors)

  BEND_SEQUENCE_VALID: 'BEND_SEQUENCE_VALID',
  BEND_SEQUENCE_INVALID_TIMEOUT: 'BEND_SEQUENCE_INVALID_TIMEOUT',
  BEND_SEQUENCE_INVALID_NO_SOLUTION: 'BEND_SEQUENCE_INVALID_NO_SOLUTION',
  BEND_SEQUENCE_INVALID_TOO_MANY_TRIANGLES: 'BEND_SEQUENCE_INVALID_TOO_MANY_TRIANGLES',

}

let i: keyof typeof constants
for (i in constants) {
  if (constants[i] !== i) {
    throw new Error(
      `In constants.ts, keys and value must match, but constants['${i}'] = '${constants[i]}'.`
    )
  }
}

export default constants

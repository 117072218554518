import { Arcified, Unit } from '@oshcut/oshlib'
import { PartType } from 'types'
import unitScale from '../unitScale'

export function UnfoldingTable(
  part: PartType<Arcified>,
  {
    hasSizeErrorX,
    hasSizeErrorY,
    hasSizeErrorZ,
    hasThicknessError,
    hasMultipleInnerRadii,
    hasInnerRadiiError,
    innerRadiiCount,
    innerRadiiAvg,
    innerRadiiRange,
    wasCompensationApplied,
    compensationNotNeeded,
    formedX,
    formedY,
    formedZ,
    unfoldX,
    unfoldY,
    unfoldZ,
  }: {
    hasSizeErrorX: boolean,
    hasSizeErrorY: boolean,
    hasSizeErrorZ: boolean,
    hasThicknessError: boolean,
    hasMultipleInnerRadii: boolean,
    hasInnerRadiiError: boolean,
    innerRadiiCount: number,
    innerRadiiAvg: number,
    innerRadiiRange: number,
    wasCompensationApplied: boolean,
    compensationNotNeeded: boolean,
    formedX: number,
    formedY: number,
    formedZ: number,
    unfoldX: number,
    unfoldY: number,
    unfoldZ: number,
  }
) {

  if (!part.arcified?.unfold) return null

  // In "unfold" units
  let unfoldMaterialThickness, unfoldUnit
  unfoldMaterialThickness = part.arcified.unfold.materialThickness
  unfoldUnit = part.arcified.unfold.units

  let thicknessUnit: Unit = 'in', digits = 3
  if (['cm', 'mm', 'm'].includes(part.arcified.units ?? 'in')) {
    thicknessUnit = 'mm'
    digits = 2
  }

  const bendInfo = part.arcified.bendInfoV5 ?? part.arcified.bendInfo

  return {
    component: (
      <table className='small unfoldingTable'>
        <tr>
          <td></td>
          <td className='bold'>This part</td>
          <td className='bold'>Your uploaded model</td>
        </tr>
        <tr>
          <td className={`${hasSizeErrorX || hasSizeErrorY || hasSizeErrorZ ? 'error bold' : ''}`}>Size ({part.arcified.units})</td>
          <td>
            <div className={`${hasSizeErrorX ? 'error bold' : ''}`}>{+formedX.toFixed(digits)} x</div>
            <div className={`${hasSizeErrorY ? 'error bold' : ''}`}>{+formedY.toFixed(digits)} x</div>
            <div className={`${hasSizeErrorZ ? 'error bold' : ''}`}>{+formedZ.toFixed(digits)} </div>
          </td>
          <td>
            <div className={`${hasSizeErrorX ? 'error bold' : ''}`}>{+(unfoldX / unitScale[unfoldUnit] * unitScale[part.arcified.units ?? 'in']).toFixed(digits)} x</div>
            <div className={`${hasSizeErrorY ? 'error bold' : ''}`}>{+(unfoldY / unitScale[unfoldUnit] * unitScale[part.arcified.units ?? 'in']).toFixed(digits)} x</div>
            <div className={`${hasSizeErrorZ ? 'error bold' : ''}`}>{+(unfoldZ / unitScale[unfoldUnit] * unitScale[part.arcified.units ?? 'in']).toFixed(digits)}</div>
          </td>
        </tr>
        {part.arcified.material &&
          <tr>
            <td className={`${hasThicknessError ? 'error bold' : ''}`}>Thickness ({thicknessUnit})</td>
            <td className={`${hasThicknessError ? 'error bold' : ''}`}>{+(part.arcified.material.thickness * unitScale[thicknessUnit]).toFixed(digits)}</td>
            <td className={`${hasThicknessError ? 'error bold' : ''}`}>{+(unfoldMaterialThickness / unitScale[unfoldUnit] * unitScale[thicknessUnit]).toFixed(digits)}</td>
          </tr>
        }
        {bendInfo && <>
          <tr>
            <td className={`${(hasInnerRadiiError || hasMultipleInnerRadii) && !wasCompensationApplied && !compensationNotNeeded ? 'error bold' : ''}`}>Inner bend radius ({thicknessUnit})</td>
            <td className={`${(hasInnerRadiiError) && !wasCompensationApplied && !compensationNotNeeded ? 'error bold' : ''}`}> {+ (bendInfo.innerRadiusInch * unitScale[thicknessUnit]).toFixed(digits)}</td>
            <td className={`${(hasInnerRadiiError || hasMultipleInnerRadii) && !wasCompensationApplied && !compensationNotNeeded ? 'error bold' : ''}`}>{
              Number.isNaN(innerRadiiAvg) ? 'N/A' : (
                innerRadiiRange / unitScale[unfoldUnit] < 0.01 ?
                  `${+(innerRadiiAvg / unitScale[unfoldUnit] * unitScale[thicknessUnit]).toFixed(digits)}`
                  :
                  'Multiple values'
              )
            }{wasCompensationApplied ? '*' : ''}</td>
          </tr>
          {wasCompensationApplied && <tr>
            <td colSpan={3}>* We automatically adjusted your part's bend radius to match our press brake tooling.</td>
          </tr>}
        </>}
      </table>
    )
  }
}
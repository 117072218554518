import React, { Suspense } from 'react'
import config from '../config'
import { Redirect, Route, Switch } from "react-router-dom"
import { DispatchFunction } from 'reducer'
import { PartType, StateType } from 'types'
import './css/MainView.scss'
import './css/react-tabs-custom.scss'
import { GlobalLoadingOverlay } from './GlobalLoadingOverlay'
import componentLoader from '../componentLoader'
import AcceptInvite from './AcceptInvite'
import Card from './Card'
import { useSignIn } from 'useSignIn'
import { MustSignInToViewThisPage } from './MustLoginToViewThisPage'
import { Order } from '@oshcut/oshlib'
import AffiliateRouteWrapper from 'components//RegisterWithAffiliate/AffiliateRouteWrapper'

const Catalog = React.lazy(() => componentLoader(() => import('./Catalog/Catalog').then(module => ({ default: module.Catalog }))))
const CartView = React.lazy(() => componentLoader(() => import('./CartView').then(module => ({ default: module.CartView }))))

const CheckoutViewV2 = React.lazy(() => componentLoader(() => import('./CheckoutV2/CheckoutView').then(module => ({ default: module.CheckoutView }))))
const CompleteQuoteView = React.lazy(() => componentLoader(() => import('./CompleteQuote/CompleteQuoteView').then(module => ({ default: module.CompleteQuoteView }))))

const OrderConfirmationView = React.lazy(() => componentLoader(() => import("components/CheckoutV2/OrderConfirmationView")))

const AccountView = React.lazy(() => componentLoader(() => import('./AccountView').then(module => ({ default: module.AccountView }))))
const InvoicePayView = React.lazy(() => componentLoader(() => import('./InvoicePayView').then(module => ({ default: module.InvoicePayView }))))

type PropType = {
  state: StateType
  dispatch: DispatchFunction
  enqueueUploadItem: (item: { part: PartType, orderId: Order['guid'], file: File }) => void
}

export function MainView(props: PropType) {

  const { customer, customerUser } = useSignIn()

  return (
    <Switch>

      <Route path="/cart/:orderId?/:partId?">
        <Card className='mainView'>
          <Suspense fallback={<GlobalLoadingOverlay reason='Loading' />}>
            <CartView
              state={props.state}
              dispatch={props.dispatch}
              enqueueUploadItem={props.enqueueUploadItem}
            />
          </Suspense>
        </Card>
      </Route>

      <Route path="/checkout/:orderId">
        <Suspense fallback={<GlobalLoadingOverlay reason='Loading' />}>
          {customer ? (<>
            <CheckoutViewV2 state={props.state} dispatch={props.dispatch} />
          </>) : null}
        </Suspense>
      </Route>

      {config.features.sendAsQuote && <Route path="/order/quote/:orderId">
        <Suspense fallback={<GlobalLoadingOverlay reason='Loading' />}>
          <CompleteQuoteView state={props.state} dispatch={props.dispatch} />
        </Suspense>
      </Route>}

      <Route path="/order-confirm/:orderId?">
        <Suspense fallback={<GlobalLoadingOverlay reason='Loading' />}>
          <OrderConfirmationView state={props.state} />
        </Suspense>
      </Route>

      <Route path="/catalog">
        <Card className='mainView'>
          <Suspense fallback={<GlobalLoadingOverlay reason='Loading' />}>
            <Catalog
              state={props.state}
              dispatch={props.dispatch}
              materialTypes={props.state.materialTypes}
              materialSheets={props.state.materialSheets}
              materialTubes={props.state.materialTubes}
              sheetEnabled={true}
              tubeEnabled={true}
            />
          </Suspense>
        </Card>
      </Route>

      <Route path="/pay/:invoiceUUID">
        <Suspense fallback={<GlobalLoadingOverlay reason='Loading' />}>
          <InvoicePayView
            state={props.state}
            dispatch={props.dispatch}
          />
        </Suspense>
      </Route>

      <Route path="/account">
        {customerUser ?
          <Suspense fallback={<GlobalLoadingOverlay reason='Loading' />}>
            <AccountView dispatch={props.dispatch} state={props.state} />
          </Suspense>
          : <MustSignInToViewThisPage />}
      </Route>

      <Route path="/accept_invite">
        <AcceptInvite />
      </Route>

      <Route path="/:potentialAffiliateCode">
        <AffiliateRouteWrapper />
      </Route>

      {/* <Route path="/debug-nest-quote">
        {innerHeader}
        <div className='mainView'>
          <NestQuote state={props.state} dispatch={props.dispatch} />
        </div>
      </Route> */}

      <Route path="/" render={() => <Redirect to="/cart" />} /> {/* Default route */}

    </Switch>
  )
}

// 3e4c59
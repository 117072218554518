import React, { useEffect } from 'react'
import { mul, add, Arcified, Contour, Vector } from '@oshcut/oshlib'
import { renderPc, clearRenderCache } from '../renderPc'
import { PartType } from 'types'

type PartThumbnailRendererProps = {

  /** The part to be rendered. */
  part: PartType<Arcified>,

  /** Array of contour id and color pairs. When provided, will fill the specified outer contours with the specified color, overriding the default fill color. */
  contourFillColors?: { contourId: Contour['id'], color: string }[]

  /** If a part has a rawEntities property, will render those raw entities alongside the part. */
  showRawEntities?: boolean,

  width: number,

  height: number

}

/**
 * 
 * @param props
 */
export function PartThumbnailRenderer({
  part,
  showRawEntities,
  contourFillColors,
  width,
  height
}: PartThumbnailRendererProps) {

  /**** View state ****/

  /** scale or zoom of the drawing canvas, in pixels per inch */
  const [scale, setScale] = React.useState(6)

  /** center of the drawing canvas, in inches */
  const [center, setCenter] = React.useState({ x: 0, y: 0 })


  /**** Canvas refs ****/

  /** ref to the main drawing canvas */
  const drawingRef = React.useRef<HTMLCanvasElement | null>(null)

  /**
   * This effect sets the pan and scale. Runs whenever viewBbox changes.
   *
   */
  React.useEffect(() => {
    if (part.arcified.bbox) {

      const drawingCanvas = drawingRef.current
      if (!drawingCanvas) return

      // First, determine the scale that will put viewBbox just barely inside the canvas.
      const scaleIfXAtLimit = drawingCanvas.width / (part.arcified.bbox.max.x - part.arcified.bbox.min.x)
      const scaleIfYAtLimit = drawingCanvas.height / (part.arcified.bbox.max.y - part.arcified.bbox.min.y)
      const _scale = Math.min(scaleIfXAtLimit, scaleIfYAtLimit) * 0.95

      // Next, center the drawing in the canvas.
      const centerOfDrawing = mul(add(part.arcified.bbox.min, part.arcified.bbox.max), 0.5)

      setScale(_scale)
      setCenter(centerOfDrawing)
    }

  }, [part.arcified.bbox])

  /**
    * Converts the given point from pixel (screen) coordinates to inches
    * @param {Point} pPixel An object with x and y properties
    * @returns {Point} The transformed point in inches
    */
  const pixelToInch = React.useCallback((pPixel: Vector) => {
    return {
      x: (pPixel.x - width / 2) / scale + center.x,
      y: (height / 2 - pPixel.y) / scale + center.y
    }
  }, [center, height, scale, width])

  /**
   * Converts the given point from inches to pixel (screen) coordinates
   * @param {Point} pInch An object with x and y properties
   * @returns {Point} The transformed point in pixels
   */
  const inchToPixel = React.useCallback((pInch: Vector) => {
    return {
      x: (pInch.x - center.x) * scale + width / 2,
      y: height / 2 - (pInch.y - center.y) * scale
    }
  }, [center, height, scale, width])


  /**** Rendering ****/


  // Render everything
  useEffect(function renderEverything() { // Using a named function to make it easier to find and to profile :-)

    const kerf = part.arcified.material?.kerf_width ?? 0.0006

    const drawingCanvas = drawingRef.current
    if (!drawingCanvas) return
    const ctx = drawingCanvas.getContext('2d')
    if (!ctx) throw new Error('Could not get 2d context')
    ctx.clearRect(0, 0, width, height)

    // Push transformation matrix: items drawn in inches will be rendered to screen coordinates
    ctx.save()
    // Don't rearrange these!!! Took forever to get them right....
    ctx.translate(0, height)
    ctx.scale(1, -1)
    ctx.translate(width / 2, height / 2)
    ctx.scale(scale, scale)
    ctx.translate(-center.x, - center.y)

    // We're in inchland now

    // Draw parts
    // Log.info(partCoords)

    clearRenderCache()

    renderPc({
      ctx,
      arcified: part.arcified,
      dfm: part.dfm,
      scale,
      position: { x: 0, y: 0 },
      rotation: 0,
      kerf,
      showRawEntities,
      contourFillColors,
      selectedContourIds: []
    })



    // Draw any other items in inch coordinates here (but that don't require the partCoord-specific rotation and position)

    // Pop matrix
    ctx.restore()

    // We're back in pixelworld now
  },
    [
      pixelToInch,
      inchToPixel,
      height,
      center,
      part.arcified.version,
      part.id,
      scale,
      width,
    ])

  return (
    <canvas ref={drawingRef}
      style={{ width: '100%' }}
      width={width}
      height={height} />
  )

}



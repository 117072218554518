import { PartThumbnailRenderer } from './PartThumbnailRenderer'
import './css/NestPartThumbnails.scss'
import { PartType } from 'types'
import { Arcified, formatCents, OrderLatestQuote } from '@oshcut/oshlib'

type PropType = {
  part: PartType<Arcified>,
  quote?: OrderLatestQuote | null,
  onClick?: (nestPartDef: any) => void
}

// TODO: Formatting looks bad when this is in the part sidebar. Too much space taken up by the price, which isn't even displayed in the part sidebar.
export function NestPartThumbnails({ part, quote = null, onClick }: PropType) {

  const arcified = part?.arcified
  if (!arcified) return null

  return (<>{
    arcified.nestedParts.filter(np => np.isCopyOfPartId === -1).map(nestPartDef => {
      let outerContour = arcified.contours.find(c => c.id === nestPartDef.id)
      if (!outerContour) return null
      let nestPart = {
        id: Math.random(),
        arcified: {
          contours: [
            outerContour,
            ...arcified.contours.filter(c => nestPartDef.innerContourIds.includes(c.id))
          ],
          bbox: outerContour.bbox,
          units: arcified.units,
          material: arcified.material
        }
      } as unknown as PartType<Arcified>
      let qty = 1 + nestPartDef.equivalentPartIds.length
      let nestPartWidth = outerContour.bbox.max.x - outerContour.bbox.min.x
      let nestPartHeight = outerContour.bbox.max.y - outerContour.bbox.min.y
      let nestPartWidthRound = Math.round(nestPartWidth * 1000 * (arcified.scaleFactor ?? 1)) / 1000
      let nestPartHeightRound = Math.round(nestPartHeight * 1000 * (arcified.scaleFactor ?? 1)) / 1000
      let priceCents = quote?.parts?.find(p => p.parentId === part.id && p.id === nestPartDef.id)?.priceEachCents
      return <div className='nestPart'>
        <div className={`nestPartThumbnail ${onClick ? 'clickable' : ''}`} onClick={() => onClick?.(nestPartDef)}><PartThumbnailRenderer part={nestPart} width={120} height={120} /></div>

        <div>
          {nestPartWidthRound} {arcified?.units} x {nestPartHeightRound} {arcified?.units}
        </div>
        <div className='center'>
          {priceCents && formatCents(priceCents)}
        </div>
        <div className='center'>
          x&nbsp;{qty}
        </div>
      </div>
    })
  }</>)
}
import { LoadingSpinner, PrimaryButton } from '@oshcut/components'
import md5 from 'md5'
import { FormEvent, useContext, useState } from 'react'
import { getRecaptchaToken } from './getRecaptchaToken'
import './SignUpForm.scss'
import { SignInContext } from 'signInContext'
import { useAlert } from 'hooks/useAlert'
import { CustomerUser, fetchPost } from '@oshcut/oshlib'
import { getErrorText } from './InvoicesView/OpenInvoices'


const SignUpForm = () => {

  const { state, dispatch } = useContext(SignInContext)

  const [first, setFirst] = useState('')
  const [last, setLast] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [updates_opt_in, setUpdatesOptIn] = useState(false)

  const [isWorking, setIsWorking] = useState(false)

  const [error, setError] = useState<string>()

  const [alertContent, showAlert] = useAlert()

  async function handleRegister(e: FormEvent) {
    if (isWorking) return

    e.preventDefault()
    if (!first || !last) {
      return setError('Please enter first and last name.')
    }

    if (!email || !password) {
      return setError('Please enter email and password.')
    }

    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)))
    {
      return setError('The email address you provided is not valid.')
    }

    setIsWorking(true)
    const recaptchaToken = await getRecaptchaToken('signup')

    let obj = {
      email,
      password: md5(password),
      first,
      last,
      updates_opt_in,
      recaptcha_token: recaptchaToken,
    }

    try {
      const result = await fetchPost('/api/v2/customer/create', obj)

      showAlert('Welcome to OSH Cut!', 'We created a new account for you at ' + result.email)
      dispatch({ type: 'ACTION_SET_CUSTOMER_USER', customerUser: result })
      dispatch({ type: 'ACTION_CLOSE_ALL_DIALOGS' })
      state.onSignInSuccess?.(result)

      let _window = window as any
      if (_window) {
        if (_window.dataLayer) {
          const convObj = {'event':'registration-submitted','conversionValue':1.0,'email': email, 'firstName': first, 'lastName': last}
          _window.dataLayer.push(convObj);
        }
        if (_window.fbq) {
          _window.fbq('track', 'CompleteRegistration', {
            value: 1.0,
            currency: 'USD',
          });
        }
      }
    } catch (ex) {
      setError(getErrorText(ex))
    } finally {
      setIsWorking(false)
    }
  }

  function moveToSignIn() {
    dispatch({ type: 'ACTION_CLOSE_ALL_DIALOGS' })
    dispatch({ type: 'ACTION_OPEN_SIGNIN_DIALOG' })
  }

  return (
    <form className='SignUpForm' onSubmit={handleRegister}>

      <div className="inputGroup">
        <div className="label"><span>First Name</span></div>
        <input
          autoFocus
          name='first'
          value={first}
          onChange={(e) => setFirst(e.target.value)}
          autoComplete="given-name"
        />
      </div>

      <div className="inputGroup">
        <div className="label"><span>Last Name</span></div>
        <input
          name='last'
          value={last}
          onChange={(e) => setLast(e.target.value)}
          autoComplete="family-name"
        />
      </div>

      <div className="inputGroup">
        <div className="label"><span>Email</span></div>
        <input
          name='email'
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="email"
        />
      </div>

      <div className="inputGroup">
        <div className="label">
          <span>Password</span>
        </div>
        <input
          name='password'
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
        />
      </div>

      <div className="checkbox">
        <label>
          <input type="checkbox" checked={updates_opt_in} onChange={_ => setUpdatesOptIn(cv => !cv)} />
          <span>Receive emails about new services, and app updates</span>
        </label>
      </div>

      {error ? <div className="error">
        {error}
      </div> : null}

      <div className="submit">
        <PrimaryButton type="submit" disabled={isWorking}>{isWorking ? <LoadingSpinner /> : 'Create Account'}</PrimaryButton>
      </div>

      <div className="signIn">
        <span>Already have an account?</span>&nbsp;
        <span className='link' onClick={moveToSignIn}>Sign In</span>
      </div>

      {alertContent}
    </form>
  )
}

export default SignUpForm
import React from 'react'
import { csrfHeader, fetchPost, Order, Part } from '@oshcut/oshlib'
import { newPart } from '../arcifyRecipes'
import { arcifyHttpTransaction } from '../arcifyHttpTransaction'
import { getMessageFromError } from '../arcifyErrorTranslator'
import { partNeedsQuote } from './useQuote'
import Log from '../logs'
import { PartType, StateType } from 'types'
import { DispatchFunction } from 'reducer'
import { getRecaptchaToken } from 'components/getRecaptchaToken'

type PropType = {
  parts: PartType[]
  parentState: StateType
  parentDispatch: DispatchFunction
}

type UploadQueueItem = {
  part: PartType
  file: File
  orderId: Order['guid']
}

type UploadQueueStateType = {
  queue: UploadQueueItem[]
  busy: boolean,
  pending: number
}

type UploadQueueAction =
  | { type: 'ENQUEUE', item: UploadQueueItem }
  | { type: 'BEGIN_ITEM' }
  | { type: 'ITEM_COMPLETE' }

export function useUploadQueue({ parts, parentState, parentDispatch }: PropType) {

  const [state, dispatch] = React.useReducer((state: UploadQueueStateType, action: UploadQueueAction) => {
    switch (action.type) {
      case 'ENQUEUE':
        // Add item to queue
        return {
          ...state,
          queue: state.queue.concat([action.item])
        }
      case 'BEGIN_ITEM':
        // Remove first item from queue and set status to busy
        return {
          ...state,
          busy: true,
          pending: state.pending + 1,
          queue: state.queue.slice(1)
        }
      case 'ITEM_COMPLETE':
        // Set status to not busy
        return {
          ...state,
          busy: state.pending <= 1,
          pending: state.pending - 1
        }
    }
  }, {
    queue: [],
    busy: false,
    pending: 0
  })

  /** Reference to the current part we are working on. If the parent state changes, we'll update this ref to match. This way, we can access the current part and whether it has been deleted at any time. */
  const part = React.useRef<PartType>()

  function enqueueItem(item: UploadQueueItem) {
    if (!item.part) throw new TypeError('item must have a property named `part`')
    if (!item.file) throw new TypeError('item must have a property named `file`')
    if (!item.orderId) throw new TypeError('item must have a property named `orderId`')
    dispatch({ type: 'ENQUEUE', item })
  }

  React.useEffect(() => {

    async function doTheThing() {

      if (state.queue.length > 0 && state.pending < 2) {
        // Begin processing the first item in the queue
        let item = state.queue[0]
        let partId = item.part.id
        Log.info('Beginning item ' + partId)
        dispatch({ type: 'BEGIN_ITEM' })

        // Update ref to the part
        part.current = parts.find(p => p.id === partId)
        if (!part.current) {
          throw new Error('Cannot find part in parts')
        }

        try {
          if (part.current.deleted) throw new Error('Part has been deleted')
          await uploadFile(item.file, item.part)

          if (part.current.deleted) throw new Error('Part has been deleted')

          // Change part status to converting
          parentDispatch({ type: 'ACTION_SET_PART_STATUS', partId, status: 'PART_STATUS_CONVERTING' })

          await convertPart(item.part)

          if (part.current.deleted) throw new Error('Part has been deleted')

          // Change part status to processing
          parentDispatch({ type: 'ACTION_SET_PART_STATUS', partId, status: 'PART_STATUS_PROCESSING' })

          let arcified = await processDxf(item.part.id)

          // Processing was successful
          parentDispatch({ type: 'ACTION_ARCIFIED_LOADED', partId, arcified: arcified })
          parentDispatch({ type: 'ACTION_SET_PART_STATUS', partId, status: 'PART_STATUS_READY', dfmStatus: 'DFM_STATUS_INVALID' })
          if (partNeedsQuote({ arcified: arcified, status: 'PART_STATUS_READY', deleted: false })) {
            parentDispatch({ type: 'ACTION_INVALIDATE_QUOTE' })
          }

          if (part.current.deleted) throw new Error('Part has been deleted')
          await createOrderPart(item.file, item.part, item.orderId)

        } catch (ex: any) {
          // Handle various kinds of errors here, dispatching messages and so forth.
          if (ex instanceof UploadError) {
            parentDispatch({
              type: 'ACTION_SET_PART_STATUS',
              partId,
              status: 'PART_STATUS_UPLOAD_ERROR',
              message: ex.message
            })
          } else if (ex instanceof ConvertError) {
            parentDispatch({
              type: 'ACTION_SET_PART_STATUS',
              partId,
              status: 'PART_STATUS_CONVERT_ERROR',
              message: ex.message
            })
          } else if (ex instanceof InvalidSvgError) {
            parentDispatch({
              type: 'ACTION_SET_PART_STATUS',
              partId,
              status: 'PART_STATUS_CONVERT_ERROR',
              message: ex.message
            })
          } else if (ex instanceof AppearsToBeAnImageError) {
            parentDispatch({
              type: 'ACTION_SET_PART_STATUS',
              partId,
              status: 'PART_STATUS_CONVERT_ERROR',
              message: ex.message
            })
          } else if (ex instanceof ContainsNoEntitiesError) {
            parentDispatch({
              type: 'ACTION_SET_PART_STATUS',
              partId,
              status: 'PART_STATUS_CONVERT_ERROR',
              message: ex.message
            })
          } else if (ex instanceof UnfoldError) {
            parentDispatch({
              type: 'ACTION_SET_PART_STATUS',
              partId,
              status: 'PART_STATUS_UNFOLD_ERROR',
              message: ex.message
            })
          } else if (ex instanceof ProcessingError) {
            parentDispatch({
              type: 'ACTION_SET_PART_STATUS',
              partId,
              status: 'PART_STATUS_PROCESSING_ERROR',
              message: ex.message
            })
          } else {
            if (/Part has been deleted/.test(ex.message)) {
              Log.info('Part has been deleted')
            } else {
              // Some other error, so rethrow
              Log.error(ex)
              throw ex
            }
          }
        } finally {
          Log.info('Item complete ' + item.part.id)
          dispatch({ type: 'ITEM_COMPLETE' })
        }
      }
    }

    doTheThing()

  }, [state.queue, state.busy, state.pending])

  // Keep our reference to the current part up to date
  React.useEffect(() => {
    if (part.current) {
      part.current = parts.find(p => p.id === part.current!.id)
    }
  }, [parts])

  async function uploadFile(file: File, newPart: PartType) {

    Log.info(file)
    if (file.size > 50e6) {
      throw new UploadError('File is too large - the maximum file size is 50 MB.')
    }

    const recaptchaToken = await getRecaptchaToken('cart_add')

    let formData = new FormData()
    formData.append('file', file, file.name)

    formData.append('partId', newPart.id)
    if (recaptchaToken) formData.append('recaptcha_token', recaptchaToken)

    // Upload the part
    let response
    try {
      response = await fetch('/api/v2/upload', {
        method: 'POST',
        headers: csrfHeader,
        body: formData
      })
    } catch (ex) {
      // Not sure what kind of error this is
      Log.error(ex)
      throw new UploadError()
    }

    const responseText = await response.text()
    // Successful upload will contain a partId
    const jsonResponse = JSON.parse(responseText)

    if (jsonResponse.error) { // error properties only result in exceptions if using fetchPost. We used fetch above (because multipart/formdata)
      Log.error(jsonResponse.error)
      if (/There was an error unfolding your part/.test(jsonResponse.error)) {
        throw new ConvertError()
      } else {
        throw new UploadError()
      }
    }

    const wasUploadSuccessful = !!jsonResponse.partId

    if (!wasUploadSuccessful) {
      Log.error('No partId received after upload')
      throw new UploadError()
    }

  }

  async function convertPart(part: PartType) {
    // Convert the part
    let response
    try {
      response = await fetchPost('/api/v2/convertUploadedPart', { partId: part.id })
    } catch (ex: any) {
      Log.error(ex.serverMessage)
      if (/There was an error unfolding your part/.test(ex.serverMessage)) {
        throw new UnfoldError(ex.serverMessage)
      } else if (/Not a valid SVG file/.test(ex.serverMessage)) {
        throw new InvalidSvgError()
      } else if (/Appears to be an image/.test(ex.serverMessage)) {
        throw new AppearsToBeAnImageError()
      } else if (/File contains no supported entities/.test(ex.serverMessage)) {
        throw new ContainsNoEntitiesError()
      } else {
        throw new ConvertError()
      }
    }
  }

  async function createOrderPart(file: File, newPart: PartType, orderId: Order['guid']) {

    // Create the order_part
    let order_part_response
    try {
      order_part_response = await fetchPost('/api/v2/order_part/add_to_order', {
        order_guid: orderId,
        part_id: newPart.id,
        qty: 1,
        name: file.name,
        display_order: newPart.displayOrder,
      })
    } catch (ex) {
      Log.error(ex)
      // Technically not a processing error (more of some kind of db error?)
      parentDispatch({ type: 'ACTION_SET_PART_STATUS', partId: newPart.id, status: 'PART_STATUS_PROCESSING_ERROR' })
      return
    }
    Log.info('order_part created: ', order_part_response)
    let _window = window as any
    if (_window) {
      Log.info('Window exists')
      if (_window.dataLayer) {
        Log.info('Datalayer exists')
        _window.dataLayer.push({ 'event': 'add-to-cart', 'conversionValue': 1 })
      }
      if (_window.fbq) {
        Log.info('fbq exists')
        _window.fbq('track', 'AddToCart', {
          value: 1.0,
          currency: 'USD',
        })
      }
    }

    if (order_part_response.action === 'create') {
      parentDispatch({ type: 'ACTION_CREATE_ORDER_PART', order_part: order_part_response.order_part })
    } else {
      parentDispatch({ type: 'ACTION_SET_QUANTITY', partId: newPart.id, quantity: order_part_response.order_part.qty })
    }

  }

  async function processDxf(partId: Part['id']) {



    const operations = newPart(false, parentState.useV5BendInfo)

    // TODO: Disable full: true if we don't need the response to include the arcified results (but, we probably do)

    // Process the part
    let arcifyResults
    try {
      arcifyResults = await arcifyHttpTransaction({
        partId,
        version: 'dxf',
        operations,
        full: true,
        description: 'Uploaded part',
      },
        (updateMsg: any) => {
          if (updateMsg.pct) {
            parentDispatch({ type: 'ACTION_SET_PART_STATUS', partId, statusProgress: updateMsg.pct })
          }
        })
    } catch (ex: any) {
      Log.error('Arcify returned error:')
      Log.error(ex)
      throw new ProcessingError(getMessageFromError(ex.serverMessage || ex.message))
    }

    for (let op of arcifyResults.operations) {
      if (op.result.error) {
        Log.error(`Error during arcify operation ${op.operation.type}:`)
        Log.error(op.result.error)
        throw new ProcessingError(getMessageFromError(op.result.error))
      }
    }


    if (!arcifyResults.full) {
      Log.error('Failed to get arcify result')
      Log.error(arcifyResults)
      throw new ProcessingError()
    }

    return arcifyResults.full

  }

  return {
    enqueueItem,
    busy: state.busy,
    pending: state.pending,
    queueLength: state.queue.length
  }

}

class UploadError extends Error {
  constructor(message = 'There was an error uploading your part.') {
    super(message)
  }
}

class ConvertError extends Error {
  constructor(message = 'There was an error converting your part.') {
    super(message)
  }
}

class UnfoldError extends Error {
  constructor(message = 'There was an error unfolding your solid part.') {
    super(message)
  }
}

class InvalidSvgError extends Error {
  constructor(message = 'Not a valid SVG file.') {
    super(message)
  }
}

class AppearsToBeAnImageError extends Error {
  constructor(message = `Your file appears to be an image. We can't process this type of file.`) {
    super(message)
  }
}

class ContainsNoEntitiesError extends Error {
  constructor(message = `Your file contains no entities that we support.`) {
    super(message)
  }
}

class ProcessingError extends Error {
  constructor(message = 'There was an error processing your part.') {
    super(message)
  }
}

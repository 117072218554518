import { useEffect, useState } from "react"
import { useSignIn } from "useSignIn"
import './css/MustSignInToViewThisPage.scss'
import { Link } from "react-router-dom"
import { LoadingSpinner } from "@oshcut/components"

export function MustSignInToViewThisPage() {

  const { showSignIn, hasTriedSignInOnLoad, areAnySignInDialogsOpen } = useSignIn()

  const [hasShownSignIn, setHasShownSignIn] = useState(false)

  useEffect(() => {
    if (hasTriedSignInOnLoad && !hasShownSignIn) {
      showSignIn()
      setHasShownSignIn(true)
    }
  }, [showSignIn, hasShownSignIn, hasTriedSignInOnLoad])

  return (
    <div className='MustSignInToViewThisPage'>
      {!hasTriedSignInOnLoad &&
        <div>
          <h2 style={{ display: 'flex', alignItems: 'center', gap: 8 }}><LoadingSpinner /> Signing in...</h2>
        </div>
      }
      {hasTriedSignInOnLoad && !areAnySignInDialogsOpen &&
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <h2>Please <span className='link' onClick={showSignIn}>sign in</span> to view this page.</h2>
          <p><Link to='/cart'>Return to cart</Link></p>
        </div>
      }
    </div >
  )
}